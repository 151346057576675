//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import { Link } from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'

import { useNavigate } from 'react-router-dom';
import { ReactComponent as PurpleLoader } from './purple_loader.svg';

const Login = () => {
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);

    const [is_submitting, SetSubmitting] = useState(false);
    useEffect(() => {
        scripts.fix_page_contents_class_height();

    }, [])


    const handleChange =  (event) => {
        $('#errors_div').html("");
    }
    const handleSubmit = (event) => {
        event.preventDefault();


        const data = new FormData(event.target);


        var email = data.get('email');
        var password = data.get('password');
        var errors_arr = [];
        var errors_str = '';
        if(email==''){

            errors_arr.push("please enter email");
        }
        else {

        }
        if(password==''){
            errors_arr.push("please enter password");
        }

        console.log(window.api_url);
        if(errors_arr.length>0){
            var errors_str = errors_arr.join(", ");
            $('#errors_div').html(errors_str);
            return;
        }

            $('#errors_div').html(errors_str);

        SetSubmitting(true);

        $.post(window.api_url+"members/session?inapp=1",{"email": email,
            "password": password,
            "currency":"1"},
            function(data){
        SetSubmitting(false);

            console.log(data);
            if(data.token){

                scripts.setCookie("token",data.token);
                    navigate('/Projects');

            }

        },'json').fail(function(error){

            SetSubmitting(false);


            try {
            $('#errors_div').html(error.responseJSON.error);
            }
            catch(err){
                alert("unknown error");
            }

        });






    }


    return (
        <div>

            <div className="allpagewrapper">
        <div className="leftbar">
            <Logo hide_navigation="1" />

        </div>

            <div className="pagecontents">
                <h1>Login</h1>
<div className="form">
    <form action="" onSubmit={handleSubmit}>

    <div className="label_wrapper">
        <label htmlFor="username">Email</label>
        <div>
        <input onChange={handleChange} id="email" type="email" name="email" />
        </div>

    </div>

    <div className="label_wrapper">
        <label htmlFor="password">Password</label>
        <div>
            <input  onChange={handleChange} id="password" type="password" name="password" />
        </div>

    </div>


    <div className="submitor">

        <input disabled={is_submitting} type="submit" value="Login" />

        {is_submitting ?
        <div className="loader_div">
        <PurpleLoader height={40} />
        </div> : ''}


    </div>

        <div className="errors" id="errors_div">

        </div>


        <div className="forgot_password">
            <span className="a_top">Forgot Password?</span>
            <span className="a_bottom">&nbsp;
                <Link to="/forgotpassword">Reset</Link>
        </span>

        </div>


        <div className="already_registered">
            <span className="a_top">Not Registered?</span>
            <span className="a_bottom">&nbsp;
                <Link to="/register">Register</Link>
        </span>

        </div>






    </form>
</div>
            </div>
            </div>
        </div>

    )
}

export default Login