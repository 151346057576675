import React from 'react'
import {setCookie} from "../Scripts";
import {Link} from 'react-router-dom';
import $ from 'jquery';

class Logo extends React.Component{


    go_to_projects(){


    }
    render() {

        return (

            <div>
                {!this.props.hide_navigation ?
                <div id="mobile">


                    <div className="nav-container">

                        <div className="hamburger_container"
                             onClick={(e) => {

                                 $('.hamburger-lines').addClass('asdasdasd').click(function(){

                                     if($(this).hasClass('open')){
                                         $('#mobilemenu').css('display','none');
                                         $(this).removeClass('open');
                                     } else {
                                         $(this).addClass('open');
                                         $('#mobilemenu').css('display','block');
                                     }

                                 });


                             }}
                        >

                        <div className="hamburger-lines">
                            <span className="line line1"></span>
                            <span className="line line2"></span>
                            <span className="line line3"></span>
                        </div>
                    </div>
                        </div>


                    <div id="mobilemenu">

                                <div className="menu_icons">
                        <ul>
                            <li>
                        <Link to="/projects"><span className="ico"></span>&nbsp;&nbsp;Projects</Link>
                        <Link to="/reporting"><span className="ico reporting"></span>&nbsp;&nbsp;Reporting</Link>
                        <Link to="/account"><span className="ico account"></span>&nbsp;&nbsp;Account</Link>

                            </li>
                        </ul>
                    </div>

                    </div>
                    <div className="moblogo">
                        <img src="/logo.png" alt=""/>

                    </div>


                </div>
                    : ''}
            <div id="desktop">
        <div className="logo">

            <span className="pro_badge">
                PRO
            </span>
                <img src="/logo.png" alt=""/>
            <div className="txt">
Ad Test Media
            </div>
        </div>


                {!this.props.hide_navigation ?
<div>
                    <div id="leftbar_buttons">

    <ul>
        <li>
            <Link to="/projects"><span className="ico projects"></span>&nbsp;&nbsp;Projects
            </Link>
            </li>
        <li>
            <Link to="/reporting"><span className="ico reporting"></span>&nbsp;&nbsp;Reporting</Link></li>
        <li>

            <Link to="/account"><span className="ico account"></span>&nbsp;&nbsp;Account</Link>
        </li>
    </ul>

</div>

</div> : ''}
                <div id="bottom_icons">
                    <ul>
                        <li><a href="mailto:support@adtest.media"><span className="ico support_ico"></span>Support</a></li>
                        {!this.props.hide_navigation ?

                        <li><a href=""                                                         onClick={(event) => {

                event.preventDefault();

                            setCookie("token","");
                            window.location.href="/Login";

                        }}><span className="ico signout_ico"></span>Sign Out</a></li>
                            : ''}
                    </ul>
                </div>



            </div>

            </div>
        )
    }

}

export default Logo;