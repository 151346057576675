//Home.js
import React, {useEffect, useMemo, useState} from 'react';
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useNavigate, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'
import Login from "./Login";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Calculator from "./components/Calculator";
import ConnectionError from "./components/ConnectionError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import {fix_page_contents_class_height} from "./Scripts";
import { ReactComponent as PurpleLoader } from './purple_loader.svg';


const CreateProject = () => {


    const { register,trigger, control,handleSubmit, formState: { errors } } = useForm();

    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);
    useEffect(() => {
        scripts.fix_page_contents_class_height();

    },[]);

    const [is_file_being_uploaded, SetIsFileBeingUploaded] = useState(false);
    const [is_submitting, SetSubmitting] = useState(false);
    const [data, setData] = useState(0);
    const [country_code, setCountrycode] = useState('');


    const [templates, setTemplates] = useState([]);
    const [product_price, setProductprice] = useState(0);

    const [product_id, setProductId] = useState(0);
    const [completes_options, setCompleteoptions] = useState([]);
    const [languages, setLanguages] = useState([]);


    const [number_of_completes, setCompletes] = useState(0);

    const [verification_type, setVerificationtype] = useState(0);

    var countries;

    useEffect(() => {

        if (country_code=='') {
            return;
        }


        set_template_select_values();

    },[country_code]);


    useEffect(() => {

        if(!data){
            return;
        }
            console.log("find_lang");
            trigger_country_change();

    },[data]);

        useEffect(() => {
        if(!product_id){
            return;
        }
        trigger('select_product');
        calculate_total_pricing();


    },[number_of_completes]);


    useEffect(() => {
        if(!product_id){
            return;
        }
            set_template_select_values();
            calculate_total_pricing();
            get_number_of_completes_options();


    },[product_id]);


    const [category_id, setCatId] = useState(0);

    useEffect(() => {
        if(!category_id){
            return;
        }
        trigger('category');



        set_template_select_values();
    },[category_id]);





    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // let {id} = useParams();

    useEffect(() => {

        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            const response = await fetch(window.api_url+'adtest/main/get_adtest_main_general_data?'+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();




            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    async function calculate_total_pricing(){


        try {


            var url = window.api_url+'adtest/main/get_pricing?product_id='+product_id+'&country_code='+country_code;


            if(number_of_completes){
                url+="&number_of_completes="+number_of_completes;
            }



            if($('#ver_email').is(":checked")){
                url+="&email_verification=1";
            }


            if($('#ver_sms').is(":checked")){
                url+="&sms_verification=1";
            }

            url+='&'+scripts.get_api_url_suffix();

            const response =  await fetch(url); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

                setProductprice(data.price);
            $('.estimated_cost').css('display','block');



        } catch (error) {
            console.log(error);
        }




    }
    async function get_number_of_completes_options(){
        try {

            const response =  await fetch(window.api_url+'adtest/main/get_product_completes_options?product_id='+product_id+'&'+scripts.get_api_url_suffix()); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

            setCompleteoptions(data);
            setCompletes(data[0].num_completes);

        } catch (error) {
            console.log(error);
        }
    }
    function select_product(in_product_id) {









        setProductId(in_product_id);




        $('.choose_product li').removeClass('active');

        $('.choose_product li.product'+in_product_id).addClass('active');


    }


    async function set_template_select_values(){


        console.log("cat id : "+category_id);

        if(category_id==1){

            $('input[name="ver_opt"]').prop("checked",false);
        } else {
            $('#ver_none').prop("checked",true);

        }

        console.log("prod id : "+product_id);

        if(!category_id || !product_id){
            return;
        }

        try {




            const response =  await fetch(window.api_url+'adtest/main/get_templates_by_product_and_category?country_code='+country_code+'&product_id='+product_id+'&category_id='+category_id+"&"+scripts.get_api_url_suffix()); // Replace with your API endpoint

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
            const data = await response.json();

            console.log(data);

            if(data.length){
            var new_arr = [{sid:0,surveyls_title:""},...data];

                setTemplates(new_arr);
            }

        } catch (error) {
            console.log(error);
        }


    }

  async  function set_template_values(event) {


        setCatId(event.target.value);


        if(event.target.value=="1"){
            //show verification
            $('.verification_type').addClass('show');
        } else {
            $('.verification_type').removeClass('show');
            //hide verification

        }
        console.log("cat_trig");



    set_template_select_values();





    }

    function set_number_of_completes(event) {
        setCompletes(event.target.value);
    }

    function countrychange() {
        console.log("country_changed");

        trigger_country_change();



    }
    function find_lang_in_countries(){


        var select_country = $('#country').val();

        var value_found;
        data.countries.forEach(function(country){
           if(country.code==select_country){


                    value_found = country.languages;

                    setCountrycode(country.alpha2);

           }
        });


        return value_found;


    }
    function trigger_country_change(){



        var new_lang = find_lang_in_countries();

        setLanguages(new_lang);
    }

    function show_hide_file_upload(event) {


        var value = event.target.value;


        $('.file_upload_selected').css('display','none');


            $('.text_above_image').css('display','block');

        if(value=='media'){

            $('.text_above_image label').text("Text Above Image");

            $('#file_uploader').css('display','block');
            $('.text_below_image').css('display','block');

        } else {
            $('.text_above_image label').text("Message Text");

            $('.text_below_image').css('display','none');
        }



        trigger('med_opt');

    }

    function OnSubmit() {



        SetSubmitting(true);
        const data = new FormData($('#createfrm')[0]);


        $.ajax({
            url: window.api_url+'adtest/main/adtest_create_survey_stage_one'+"?"+scripts.get_api_url_suffix(),
            type: 'post',
            data: data,
            contentType: false,
            processData: false,
            success: function(response){
                SetSubmitting(false);

                try {
                var json = $.parseJSON(response);

                console.log(json);
                    if(json.new_sid){
                        navigate('/editproject/'+json.new_sid);

                    }

                }
                catch(err){
                    console.log(err);
                }

            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {

                SetSubmitting(false);
                alert("internet_connnection_problem");


            }
        });


    }
    const uploadFile = (files) => {
        console.log("Uploading file...");
        const API_ENDPOINT = window.api_url+'adtest/main/upload_media_file_uploader'+"?"+scripts.get_api_url_suffix();
        const request = new XMLHttpRequest();
        const formData = new FormData();

        request.open("POST", API_ENDPOINT, true);
        request.onreadystatechange = () => {
            if (request.readyState === 4 && request.status === 200) {

                try {
                    var json = $.parseJSON(request.responseText);


                    var muf_id = json.muf_id;

                    $('#muf_id').val(muf_id);

                    SetIsFileBeingUploaded(false);

                    trigger('upload_file');


                }
                catch(err){

                }





            }
        };

        for (let i = 0; i < files.length; i++) {
            formData.append("myfile", files[i]);
        }
        request.send(formData);
    };
    async function upload_file(event) {

        const files = event.target.files;

            var file_name = files[0].name;

        var ext = file_name.substr(file_name.lastIndexOf('.') + 1);


        if (ext !== 'png' && ext !== 'PNG'
            && ext !== 'jpg' && ext !== 'JPG'
            && ext !== 'gif' && ext !== 'GIF'
            && ext !== 'MP4'
            && ext !== 'mp4'

        ) {

            alert('wrong_file_format');

            $("#file_upload").val(null);


        }

        // trigger('upload_file');


        SetIsFileBeingUploaded(true);

        uploadFile(files);

    }

    function ver_opt_calculate_total_pricing() {
        trigger('ver_opt');
        calculate_total_pricing();
    }

    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>Setup</h1>
                    <div className="page_contents_wrapper">


                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (

            <ConnectionError />
                        ) : (

                                <div className="form createprojectfrm" onSubmit={handleSubmit(OnSubmit)} noValidate>
                                    <form id="createfrm" action="">
                                        <input type="hidden" name="country_code" value={country_code} />
                                        <input type="hidden" name="product_price" value={product_price} />
                                        <div className="select_country">


                                            <div className="label_wrapper col-6 first">
                                                <label htmlFor="country">Country</label>
                                                <select onChange={countrychange} name="country" id="country">
                                                    {data.countries.map((item) => (
                                                        <option key={item.code} value={item.code}>{item.langEN}</option>
                                                    ))
                                                    }
                                                </select>
                                            </div>

                                            <div className="label_wrapper col-6 first">
                                                <label htmlFor="language">Language</label>
                                                <select name="language" id="language">
                                                    {languages.map((item) => (
                                                        <option key={item.cl_id} value={item.language}>{item.language}</option>
                                                    ))
                                                    }
                                                </select>
                                            </div>



                                        </div>


                                        <div className="label_wrapper">
                                            <label htmlFor="project_name">Project Name</label>

                                            <div>
                                                <input id="project_name" type="text" name="project_name"  aria-invalid={errors.project_name ? "true" : "false"} {...register('project_name', { required: true})} />

                                                {errors.project_name && errors.project_name.type === "required" && <span
                                                    className="error_span">Project name is required</span>}

                                            </div>

                                        </div>


                                        <div className="media_type">
                                            <h4><span>Type of media to test</span></h4>

                                            <div className="media_options">

                                            <span className="med_opt">
                                                <input {...register('med_opt', { required: true})} value="media" onChange={show_hide_file_upload} type="radio" name="med_opt" id="med_media" /> <label htmlFor="med_media">Media(Image or Video)</label>
                                            </span>


                                                <span className="med_opt">
                                                <input {...register('med_opt', { required: true})} value="none" onChange={show_hide_file_upload} type="radio" name="med_opt" id="med_none" /> <label
                                                    htmlFor="med_none">Message</label>
                                            </span>
                                                {errors.med_opt && errors.med_opt.type === "required" && <div
                                                    className="error_span">Please select media type</div>}

                                            </div>

                                            <div className="label_wrapper text_above_image">
                                                <label htmlFor="lead_text"></label>
                                                <div>
                                                    <input type="text" id="lead_text" name="lead_text" {...register('lead_text', { required: true})}  aria-invalid={errors.lead_text ? "true" : "false"} />
                                                    {errors.lead_text && errors.lead_text.type === "required" && <span
                                                        className="error_span">Lead Text is required</span>}

                                                </div>

                                            </div>


                                            <input type="hidden" name="muf_id" id="muf_id" val="" />
                                            <div className="file_upload_selected" id="file_uploader">
                                                <div className="label_wrapper position_relative">
                                                    <label htmlFor="image_file">Select File</label>


                                                    {is_file_being_uploaded ?
                                                        <span className="file_upload_think"><PurpleLoader height={40} /></span> : ''}


                                                    <input {...register('upload_file', {    validate:function(value){if($('input[name="med_opt"]:checked').val() && $('input[name="med_opt"]:checked').val()!=='none' && (!$('#file_upload').val() || !$('#muf_id').val())) return 'xxxPlease Select a file.'}})}  onChange={upload_file} id="file_upload" type="file" />




                                                    {errors.upload_file && <span
                                                        className="error_span">File is required</span>}
                                                </div>




                                            </div>

                                            <div className="label_wrapper text_below_image">
                                                <label htmlFor="lead_text">Text Below media</label>
                                                <div>
                                                    <input type="text" id="end_text" name="end_text" />
                                                </div>

                                            </div>


                                        </div>



                                        <div className="choose_product">
                                            <h4><span>Choose a Product:</span></h4>
                                            <div {...register('select_product', {    validate: function(){if(!product_id) return 'false';}})}>
                                                {errors.select_product && <div
                                                    className="error_span">Please select a product</div>}
                                            </div>
                                            <input type="hidden" name="product_id" value={product_id} />
                                            <ul>
                                                {data.adtest_products.map((item) => (
                                                    <li key={item.product_id} className={'product'+item.product_id} key={item.product_id}>

                                                        <span className="icon"></span>
                                                        <div className="line_one">
                                                            {item.product_name}
                                                        </div>
                                                        <div className="line_two">
                                                            {item.product_short_desc}
                                                        </div>

                                                        <div className="actions_lane">

                                                            <Popup
                                                                trigger={<span className="readmore_product"><FontAwesomeIcon className="prpl" icon={faCircleInfo} /> View More </span>}
                                                                modal
                                                                nested
                                                            >
                                                                {close => (
                                                                    <div className="inmodal">
                                                                        <button className="close" onClick={close}>
                                                                            &times;
                                                                        </button>
                                                                        <div className="header"> {item.product_name} </div>
                                                                        <div className="content">
                                                                            {item.product_long_desc}
                                                                        </div>
                                                                        <div className="actions">

                                                                            <div className="in_actions">


                                                                            <button
                                                                                className="button select_product"
                                                                                onClick={() => {
                                                                                    console.log('modal closed ');
                                                                                    select_product(item.product_id);

                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Select Product
                                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Popup>
                                                            <div className="clear"></div>
                                                            <a onClick={()=>select_product(item.product_id)} className="select_product">Select</a>


                                                        </div>

                                                    </li>
                                                ))}
                                            </ul>




                                        </div>

                                        <div className="select_template">

                                            <h4><span>Select Template:</span></h4>

                                            <div className="label_wrapper col-6 first">
                                                <label htmlFor="category">Category</label>
                                                <select {...register('category', {    validate:function(value){if(!$('#category').val()) return 'no_value';}})} onChange={set_template_values} name="category" id="category"  aria-invalid={errors.category ? "true" : "false"}>
                                                    <option value=""></option>
                                                    {data.adtest_categories.map((item) => (
                                                        <option key={item.asc_id} value={item.asc_id}>{item.category_title}</option>
                                                    ))
                                                    }

                                                        </select>

                                                {errors.category && <span
                                                    className="error_span">Category is required</span>}

                                            </div>

                                            <div className="label_wrapper col-6">
                                                <label htmlFor="template">Template</label>
                                                <select {...register('template', {    validate:function(value){if(!$('#template').val() || $('#template').val()=="0" ) return 'no_value';}})} name="template" id="template">
                                                    {templates.map((item) => (
                                                        <option key={item.sid} value={item.sid}>{item.surveyls_title}</option>
                                                    ))
                                                    }

                                                </select>


                                                {errors.template && <span
                                                    className="error_span">Template is required</span>}


                                            </div>


                                        </div>



                                        <div className="number_of_completes">
                                            <div className="label_wrapper">
                                                <label htmlFor="number_of_completes">Number of completes</label>
                                                <select onChange={set_number_of_completes} name="number_of_completes" id="number_of_completes">
                                                    {completes_options.map((item) => (
                                                        <option key={item.appo_id} value={item.num_completes}>{item.num_completes}</option>
                                                    ))
                                                    }
                                                </select>

                                            </div>

                                        </div>




                                        <div className="verification_type">
                                            <h4><span>Verification type</span></h4>

                                            <div className="verification_options">

                                            <span className="ver_opt">
                                                <input {...register('ver_opt', { required: true})}  onChange={ver_opt_calculate_total_pricing} type="radio" name="ver_opt" value="email" id="ver_email" /> <label htmlFor="ver_email">Email</label>
                                            </span>

                                                <span className="ver_opt">
                                                <input {...register('ver_opt', { required: true})} onChange={ver_opt_calculate_total_pricing} type="radio" name="ver_opt" id="ver_sms" value="sms" /> <label htmlFor="ver_sms">SMS</label>
                                            </span>

                                                <span className="ver_opt">
                                                <input {...register('ver_opt', { required: true})} onChange={ver_opt_calculate_total_pricing} type="radio" name="ver_opt" id="ver_none" value="" /> <label
                                                    htmlFor="ver_none">None</label>
                                            </span>


                                                {errors.ver_opt && errors.ver_opt.type === "required" && <div
                                                    className="error_span">Please select verification type</div>}


                                            </div>
                                        </div>


                                        <Calculator product_price={product_price} display="false" />


                                        <div className="submitor">
                                            {is_submitting ?
                                                <PurpleLoader height={40} /> : ''}
                                            <input disabled={is_submitting} type="submit" value="Continue Setup" />


                                        </div>

                                    </form>

                                    {/*<DevTool control={control} /> /!* set up the dev tool *!/*/}

                                    <div className="clear"></div>
                                </div>



                            )}



                    </div>
                </div>
            </div>

        </div>
    );
};
export default  CreateProject