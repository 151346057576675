//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import { Link } from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'

import { useNavigate } from 'react-router-dom';
import { ReactComponent as PurpleLoader } from './purple_loader.svg';

const Forgotpassword = () => {
    const navigate = useNavigate();

    const [is_submitting, SetSubmitting] = useState(false);
    useEffect(() => {
        scripts.fix_page_contents_class_height();

    }, [])


    const handleChange =  (event) => {
        $('#errors_div').html("");
    }
    const handleSubmit = (event) => {
        event.preventDefault();


        const data = new FormData(event.target);


        var email = data.get('email');
        var errors_arr = [];
        var errors_str = '';
        if(email==''){

            errors_arr.push("please enter email");
        }
        else {

        }

        console.log(window.api_url);
        if(errors_arr.length>0){
            var errors_str = errors_arr.join(", ");
            $('#errors_div').html(errors_str);
            return;
        }

        $('#errors_div').html(errors_str);

        SetSubmitting(true);

        $.post(window.api_url+"members/password_reset?inapp=1",{"password_sent_to": email},
            function(data){
                SetSubmitting(false);


                if(data.error){
                    $('#errors_div').html(data.error);
                    return;
                }


                if(data.status=='ok'){

                    alert("Password was successfully sent to your email.");

                }

            },'json').fail(function(error){

            SetSubmitting(false);
            $('#errors_div').html(error.responseJSON.error);

        });






    }


    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo hide_navigation="1" />

                </div>

                <div className="pagecontents">
                    <h1>Forgot Password</h1>
                    <div className="form">
                        <form action="" onSubmit={handleSubmit}>

                            <div className="label_wrapper">
                                <label htmlFor="username">Email</label>
                                <div>
                                    <input onChange={handleChange} id="email" type="email" name="email" />
                                </div>

                            </div>




                            <div className="submitor">

                                <input disabled={is_submitting} type="submit" value="Reset Password" />

                                {is_submitting ?
                                    <div className="loader_div">
                                        <PurpleLoader height={40} />
                                    </div> : ''}


                            </div>

                            <div className="already_registered">
                                <span className="a_top">Know your password?</span>
                                <span className="a_bottom">&nbsp;
                                    <Link to="/login">Login</Link>
        </span>

                            </div>

                            <div className="errors" id="errors_div">

                            </div>




                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Forgotpassword