//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import {Link, useParams} from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'

import { useNavigate } from 'react-router-dom';
import { ReactComponent as PurpleLoader } from './purple_loader.svg';
import ConnectionError from "./components/ConnectionError";

const Reporting = () => {
    const { project_id } = useParams();
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);

    const [is_submitting, SetSubmitting] = useState(false);

    const [data, setData] = useState(0);


    const fetchData = async () => {

        try {
            const response = await fetch(window.api_url+'adtest/main/adtest_list_surveys?'+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();





            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }


    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        scripts.fix_page_contents_class_height();
        fetchData();
    }, []);



    const handleChange =  (event) => {
        $('#errors_div').html("");
    }
    const handleSubmit = (event) => {
        event.preventDefault();


        const data = new FormData(event.target);


        var email = data.get('email');
        var password = data.get('password');
        var errors_arr = [];
        var errors_str = '';
        if(email==''){

            errors_arr.push("please enter email");
        }
        else {

        }
        if(password==''){
            errors_arr.push("please enter password");
        }

        console.log(window.api_url);
        if(errors_arr.length>0){
            var errors_str = errors_arr.join(", ");
            $('#errors_div').html(errors_str);
            return;
        }

        $('#errors_div').html(errors_str);

        SetSubmitting(true);

        $.post(window.api_url+"members/session?inapp=1",{"email": email,
                "password": password,
                "currency":"1"},
            function(data){
                SetSubmitting(false);

                console.log(data);
                if(data.token){

                    scripts.setCookie("token",data.token);
                    navigate('/Projects');

                }

            },'json').fail(function(error){

            SetSubmitting(false);
            try {
                $('#errors_div').html(error.responseJSON.error);
            }
            catch(err){
                alert("unknown error");
            }

        });






    }


    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>

                <div className="pagecontents">
                    <h1>Reporting</h1>


                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                            <ConnectionError />
                        ) :
                    <div className="form">
                        <form method="post" action={window.api_url+"adtest/main/export_surveys"}>

                            <div className="label_wrapper">
                                <label htmlFor="username">Surveys</label>
                                <div>
                                    <select onChange={handleChange} id="email" type="email" name="selected_survey" value={project_id ? project_id : ""}>
                                        {data.surveys.map((survey) => (
                                            <option key={survey.sid} value={survey.sid}>{survey.surveyls_title}</option>
                                        ))
                                        }
                                    </select>
                                </div>

                            </div>

                            <div className="label_wrapper">
                                <label htmlFor="password">Exports questions as</label>
                                <div>
                                    <select onChange={handleChange} id="password" type="password" name="answer_export_type">
                                        <option value="qcode">Question Code</option>
                                    <option value="qtext">Question Text</option>
                                    </select>
                                </div>

                            </div>


                            <div className="label_wrapper">
                                <label htmlFor="password">Exports answers as</label>
                                <div>
                                    <select  onChange={handleChange} id="password" type="password" name="answer_export_type">
                                        <option value="anscode">Answer Code</option>
                                        <option value="anstext">Answer Text</option>
                                    </select>
                                </div>

                            </div>


                            <div className="include_optional">
                                <input type="checkbox" name="optional_fields"/> Include Optional Fields?
                            </div>

                            <div className="completes_only">
                                <input type="checkbox" name="completes_only"/> Completes Only?
                            </div>

                            <div className="submitor morespacing">

                                <input disabled={is_submitting} type="submit" value="Export" />

                                {is_submitting ?
                                    <div className="loader_div">
                                        <PurpleLoader height={40} />
                                    </div> : ''}


                            </div>


                            <div className="errors" id="errors_div">

                            </div>




                        </form>
                    </div>
                    }

                </div>
            </div>
        </div>

    )
}

export default Reporting;