//Home.js
import React, {useEffect, useState} from 'react'
import $ from 'jquery';
import Navbar from './Navbar'
import { Link } from "react-router-dom";
import './App.css';
import './Scripts.js';
import * as scripts from "./Scripts";
import Logo from './components/Logo'

import { useNavigate } from 'react-router-dom';
import { ReactComponent as PurpleLoader } from './purple_loader.svg';
import Popup from "reactjs-popup";
import ConnectionError from "./components/ConnectionError";
import {setCookie} from "./Scripts";
import {useForm} from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
const Account = () => {
    const navigate = useNavigate();
    scripts.on_before_load_redirect_inside(navigate);
    const { register,trigger, control,handleSubmit, formState: { errors } } = useForm();



    const [is_submitting, SetSubmitting] = useState(false);
    const [is_submitting_pass, SetSubmittingPass] = useState(false);

    const [data, setData] = useState(0);


    const fetchData = async () => {

        try {
            const response = await fetch(window.api_url+'adtest/main/get_adtest_account_data?'+scripts.get_api_url_suffix()); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();


            if(data.fullname){
                setBaseFullName(data.fullname);
                setUpdatedFullName(data.fullname);
            }


            if(data.client_name){
                setBaseClientName(data.client_name);
                setUpdatedClientName(data.client_name);
            }



            setData(data);

            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }

    }

    const [base_fullname, setBaseFullName] = useState('');
    const [updated_fullname, setUpdatedFullName] = useState('');
    const [updated_client_name, setUpdatedClientName] = useState('');
    const [base_client_name, setBaseClientName] = useState('');

    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(null);

    useEffect(() => {
        scripts.fix_page_contents_class_height();

    }, [data]);


    useEffect(() => {
        fetchData();
    }, []);


    const handleChange =  (event) => {
        $('#errors_div').html("");
    }
    function OnSubmit() {
        console.log("gotsa");
        const post_data = new FormData($('#updatedatafrm')[0]);

        if(base_fullname===updated_fullname && base_client_name===updated_client_name){

            //nothing is changed, just close popup!
            $('.inmodal .close').click();

            return;
        }

        SetSubmitting(true);

        $.ajax({
            url: window.api_url + 'adtest/main/adtest_account_update_data'+ "?" + scripts.get_api_url_suffix(),
            type: 'post',
            data: post_data,
            contentType: false,
            processData: false,
            success: function (response) {
                SetSubmitting(false);
                try {
                    var json = $.parseJSON(response);

                    console.log(json);



                        if (json.success) {


                            console.log("got_pas");

                            setUpdatedFullName(base_fullname);
                            setUpdatedClientName(base_client_name);





                            $('.inmodal .close').click();



                    }

                } catch (err) {
                    console.log(err);
                }


            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {

                SetSubmitting(false);
                alert("internet_connnection_problem");


            }

        });

    }


    function PassOnSubmit() {

        const post_data = new FormData($('#updatepassfrm')[0]);


        SetSubmittingPass(true);

        $.ajax({
            url: window.api_url + 'adtest/main/adtest_account_update_pass'+ "?" + scripts.get_api_url_suffix(),
            type: 'post',
            data: post_data,
            contentType: false,
            processData: false,
            success: function (response) {
                SetSubmittingPass(false);
                try {
                    var json = $.parseJSON(response);




                        if (json.success) {


                            setBaseFullName(updated_fullname);

                            setBaseClientName(updated_client_name);




                            $('.inmodal .close').click();



                    }

                } catch (err) {
                    console.log(err);
                }


            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {

                SetSubmittingPass(false);
                alert("internet_connnection_problem");


            }

        });

    }

    function project_data_row(label, text) {


        return <div className="data_row">
            <span className="lbl">{label}</span>
            |
            <span className="txt">{text}</span>
        </div>
    }

    function do_view_edit_box() {
        return                                       <Popup
            trigger={

                <div className="view_edit">
                <div className="in_wrapper">
                    <div className="in_wrap">

                        <span className="ico"></span>
                        <span className="txt">
                                            View/<br />Edit
                                        </span>
                    </div>
                </div>
                </div>

            }
            modal
            nested
        >
            {close => (
                <div className="inmodal">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="header">Update Setup </div>
                    <div className="content">


                        <div className="update_setup_form">

                            <form id="updatedatafrm" action=""  onSubmit={handleSubmit(OnSubmit)} noValidate>
                                <div className="label_wrapper">
                                    <label htmlFor="project_title">Full Name</label>

                                    <div>
                                        <input  {...register('fullname', {validate:function(value){

                                                if($('#updatedatafrm').length>0 && ($('#fullname').val() =="")){
                                                    return 'wrong';
                                                }

                                            }})} type="text" value={base_fullname}
                                               name="fullname" id="fullname"
                                               onChange={(e) => {


                                                   trigger('fullname');
                                                   setBaseFullName(e.target.value);
                                                   // setProjectTitle(e.target.value);

                                               }}
                                        />
                                        {errors.fullname && <div
                                            className="error_span">Full name cannot be empty</div>}

                                    </div>
                                </div>


                                <div className="label_wrapper">
                                    <label htmlFor="client_name">Client Name</label>

                                    <div>
                                        <input
                                            {...register('client_name', {validate:function(value){

                                                    if($('#updatepassfrm').length>0 && ($('#client_name').val() == "")){
                                                        return 'wrong';
                                                    }

                                                }})}

                                            type="text"
                                                name="client_name" id="client_name"
                                                value={base_client_name}
                                                onChange={(e) => {

                                                    trigger('client_name');
                                                    setBaseClientName(e.target.value);



                                                }} />

                                        {errors.client_name && <div
                                            className="error_span">Client name cannot be empty</div>}


                                    </div>
                                </div>



                                <div className="actions">

                                    <div className="in_actions">


                                        {is_submitting ?
                                            <PurpleLoader height={40} /> : ''}


                                        <button disabled={is_submitting}
                                                className="button select_product"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {/*<DevTool control={control} /> /!* set up the dev tool *!/*/}

                        </div>
                    </div>
                </div>
            )}
        </Popup>;
    }



    return (
        <div>

            <div className="allpagewrapper">
                <div className="leftbar">
                    <Logo />

                </div>


                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                        <ConnectionError />
                    ) :
                <div className="pagecontents">



                    <div className="confirmation_data">
                        <div className="credits_headline">
                            Credits: {data.total_credits}
                        </div>

                        <h1>Account </h1>



                        <div className="project_data_box">

                            <div className="heading"><span className="menu_icon setup_icon"></span> Setup</div>


                            <div className="data_boxes_wrapper">

                                {do_view_edit_box('details')}

                                <div className="data_boxes_left">

                                    <div className="data_box">


                                        {project_data_row("Email ",data.my_email)}


                                        {project_data_row("Fullname",updated_fullname)}

                                        {project_data_row("Client Name",data.client_name)}


                                    </div>
                                </div>
                                <div className="data_boxes_right">

                                    <div className="data_box">


                                    </div>

                                </div>



                                <div className="clear"></div>
                            </div>

                        </div>

                        <div className="account_down_buttons">

                            <Popup
                                trigger={

                            <span className="change_password" href="">Change Password</span>

                                }
                                modal
                                nested
                            >
                                {close => (
                                    <div className="inmodal">
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="header">Change Password </div>
                                        <div className="content">


                                            <div className="update_setup_form">


                                                <form id="updatepassfrm" action=""  onSubmit={handleSubmit(PassOnSubmit)}  noValidate>
                                                    <div className="label_wrapper">
                                                        <label htmlFor="password">New Password</label>

                                                        <div>
                                                            <input  {...register('password', {validate:function(value){


                                                                if($('#updatepassfrm').length>0 && $('#password').val()==""){
                                                                    return 'wrong';
                                                                }

                                                                }})}
                                                                                                                                   type="password"
                                                                   name="password" id="password"
                                                                   onChange={(e) => {


                                                trigger('password');
                                                                   }}
                                                            />

                                                            {errors.password && <div
                                                                className="error_span">Please enter password.</div>}

                                                        </div>

                                                    </div>


                                                    <div className="label_wrapper">
                                                        <label htmlFor="passwordconfirm">Password Confirm</label>

                                                        <div>
                                                            <input
                                                                {...register('passwordconfirm', {validate:function(value){

                                                                    if($('#updatepassfrm').length>0 && ($('#passwordconfirm').val() !== $('#password').val()
                                                                    || $('#passwordconfirm').val()=="")
                                                                    ){
                                                                        return 'wrong';
                                                                    }

                                                                }})}


                                                                type="password"
                                                                   name="passwordconfirm" id="passwordconfirm"
                                                                   onChange={(e) => {


                                                                       trigger('passwordconfirm');

                                                                   }} />

                                                            {errors.passwordconfirm && <div
                                                                className="error_span">Please enter the same password again.</div>}


                                                        </div>
                                                    </div>



                                                    <div className="actions">

                                                        <div className="in_actions">



                                                            {is_submitting_pass ?
                                                                <PurpleLoader height={40} /> : ''}

                                                            <button disabled={is_submitting_pass}
                                                                    className="button select_product"
                                                                    onClick={() => {
                                                                        console.log('modal closed ');

                                                                        // close();
                                                                    }}
                                                            >
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {/*<DevTool control={control} /> /!* set up the dev tool *!/*/}


                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>


                            <a

                                onClick={(event) => {

                                    event.preventDefault();

                                    setCookie("token","");
                                    window.location.href="/Login";

                                }}

                                className="logout" href="">Logout</a>
                        </div>



                    </div>

                </div>
                }

            </div>
        </div>

    )
}

export default Account